var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.form.$submitting
    ? _c(
        "div",
        [
          !_vm.showConfirm
            ? _c(
                "gov-button",
                { attrs: { error: "" }, on: { click: _vm.onShowConfirmation } },
                [_vm._v("Delete " + _vm._s(_vm.resource))]
              )
            : [
                _c(
                  "gov-error-summary",
                  { attrs: { title: "This action will be logged" } },
                  [
                    _c("gov-body", [
                      _vm._v(
                        "\n        Deleting this " +
                          _vm._s(_vm.resource) +
                          " will also delete all associated data. Are\n        you sure you want to delete this " +
                          _vm._s(_vm.resource) +
                          "? This action is\n        irreversible.\n      "
                      )
                    ]),
                    _c(
                      "gov-button",
                      { attrs: { error: "" }, on: { click: _vm.onDelete } },
                      [_vm._v("Confirm deletion")]
                    ),
                    _vm._v(" "),
                    _c("gov-button", { on: { click: _vm.onCancel } }, [
                      _vm._v("Cancel")
                    ])
                  ],
                  1
                )
              ]
        ],
        2
      )
    : _c("gov-button", { attrs: { disabled: "", error: "" } }, [
        _vm._v("Deleting " + _vm._s(_vm.resource) + "...")
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }